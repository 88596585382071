import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Drawer,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Portal,
  createStyles,
  withStyles
} from "@material-ui/core";

import { styled } from "@mui/material/styles";
import { CheckBoxOutlined, CheckBoxOutlineBlankOutlined, ExpandMore } from '@material-ui/icons';

import ClaimDetailedInformationController, { Props, formInitialValues } from "./ClaimDetailedInformationController.web";
import Loader from "../../../components/src/Loader.web";
import { CustomSnackbar } from "../../../blocks/forgot-password/src/ForgotPassword.web";
import { Formik, Form } from "formik";
import { renameClaimStatus } from "../../../blocks/tasks/src/ClaimsDashboardController.web";
import { EmailAccountLogout } from "../../../blocks/email-account-login/src/EmailAccountLogout.web";
import SubmitPayAmount from "../../../blocks/customform/src/SubmitPayAmount.web";

const logo = require("../../../components/src/main_logo.png");
const customDivider = require("../assets/gradient_divider.png");

const styles = createStyles({
  root: {
    display: 'flex',
    height: '100%'
  },
  leftbarContent: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 10,
    justifyContent: 'space-between',
    margin: '15px 0',
    alignItems: 'center'
  },
  logoImage: {
    width: '100px',
    height: '100px',
    margin: 0,
    justifyContent: 'center',
    marginTop: 15
  },
  mainContainer: {
    flexGrow: 1,
    padding: '50px',
    height: '100%',
    background: "#F1F5F9",
    overflow: 'scroll'
  },
  informationLabel: {
    fontSize: 16,
    fontWeight: 700,
    color: '#64748B'
  },
  inputLabel: {
    fontSize: 14,
    fontWeight: 700,
    color: '#000000'
  },
  userNameLabel: {
    fontSize: 18,
    fontWeight: 700,
    color: '#000000',
    marginLeft: 8
  },
  mainContent: {
    background: "#fff",
    width: '100%',
    height: 'auto',
    minWidth: 500,
    margin: '20px 0',
    padding: '30px 15px',
    boxShadow: '0 0 10px #00000036',
    borderRadius: 8
  },
  contentLabel: {
    margin: '0 10px'
  },
  formHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 10px'
  },
  claimStatusLabel: {
    color: '#44B400',
    fontSize: 18,
    fontWeight: 700,
  },
  claimStatusNoneLabel: {
    color: 'red',
    fontSize: 18,
    fontWeight: 700,
  },
  customButton: {
    height: 56,
    borderRadius: 8,
    border: '1px solid #000000',
    width: '100%',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 700,
    color: '#0E0F2B',
    background: '#FFFFFF',
  },
  updatedStatusButton: {
    background: '#000000',
    color: "#FFFFFF",
    height: 56,
    borderRadius: 8,
    width: '100%',
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 700,
    '&:hover': {
      background: '#333333'
    },
    '&:disabled': {
      backgroundColor: '#00000017',
      color: 'white',
    },
  },
  formControlBox: {
    margin: '0 10px 20px 10px'
  },
  buttonGroup: {
    marginTop: 50
  },
  emptySelect: {
    height: 35
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  }
});

const CustomLeftbar = styled(Drawer)(() => ({
  width: 120,
  flexShrink: 0
}));

const CustomScreenLable = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '32px',
  color: '#0F172A'
}));

const CustomClaimsLable = styled(Typography)(() => ({
  fontFamily: '"Inter", sans-serif',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
  color: '#64748B',
  marginTop: 20
}));

const CustomInput = styled(TextField)(() => ({
  backgroundColor: "transparent",
  width: '87%',
  marginBottom: '12px',
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px',
    fontSize: '18px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: 8,
      height: '44px',
      borderColor: '#000000',
    },
    '&:hover fieldset': {
      borderColor: '#000000',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#000000',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#000000',
    },
    '&.Mui-disabled .MuiOutlinedInput-input': {
      color: '#455A64',
    }
  },
}));

const CustomSelect = styled(Select)(() => ({
  height: 44,
  width: '87%',
  borderRadius: 8,
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000",
  },
  "&.Mui-disabled .MuiOutlinedInput-notchedOutline ": {
    borderColor: "#000",
  },
  '&.Mui-disabled': {
    color: '#455A64'
  },
}));

const CustomCheckbox = styled(Checkbox)(() => ({
  color: '#000000',
  '&.Mui-checked': {
    color: 'black',
  }
}));
// Customizable Area End

export class ClaimDetailedInformation extends ClaimDetailedInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      <Box className={classes.root}>
        <Loader loading={this.state.loading} />
        <CssBaseline />
        <CustomLeftbar
          variant="permanent"
          PaperProps={{ style: { width: 120, background: '#0E0F2B' } }}
          data-test-id="left-bar"
        >
          <div className={classes.leftbarContent}>
            <img src={logo?.default || logo || ""} className={classes.logoImage} />
            <EmailAccountLogout
              token={this.state.token}
            />
          </div>
        </CustomLeftbar>

        <Formik
          initialValues={formInitialValues}
          onSubmit={this.handleRequestMoreInfo}
          data-test-id="claim-formik"
          validate={this.formValidate}
        >
          {(props) => {
            this.updateClaimFormProps(props);

            return (
              <Form
                data-test-id="claim-form"
                onSubmit={props.handleSubmit}
              >
                <Box className={classes.mainContainer}>
                  <CustomScreenLable data-test-id="screen-label">Claim Form</CustomScreenLable>

                  <CustomClaimsLable data-test-id="claims-label">CLAIMS</CustomClaimsLable>

                  <Box className={classes.mainContent}>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <Box className={classes.avatarBox}>
                          <Avatar />
                          <Typography className={classes.userNameLabel}>
                            {this.state.claimData.attributes?.full_name}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <Typography className={
                          this.state.claimData.attributes?.claim_status === null ?
                            classes.claimStatusNoneLabel :
                            classes.claimStatusLabel}
                        >
                          {this.state.claimData.attributes?.claim_status === null ? "" : renameClaimStatus(this.state.claimData.attributes?.claim_status)}
                        </Typography>
                      </Grid>

                      <Grid item xs={4} style={{ textAlign: 'right', paddingRight: 20 }}>
                        <Typography className={classes.userNameLabel}>
                          {this.state.claimData.id}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <img style={{ width: '100%' }} src={customDivider?.default || customDivider || ""} />
                      </Grid>

                      <Grid item xs={12} className={classes.contentLabel}>
                        <Typography className={classes.informationLabel}>CLAIMS INFORMATION</Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Claim Category</Typography>
                          <Box>
                            <CustomSelect
                              data-test-id="claim-category"
                              disabled
                              variant="outlined"
                              IconComponent={ExpandMore}
                              value={this.state.claimData.attributes?.claim_category_id || ""}
                            >
                              <MenuItem className={classes.emptySelect} value=""></MenuItem>
                              {this.state.claimCategories.map((data, index) =>
                                <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
                              )}
                            </CustomSelect>

                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_category"
                              checked={props.values.request_category}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox} style={{ marginTop: 30 }}>
                          <Typography className={classes.inputLabel}>Full Name</Typography>
                          <Box>
                            <CustomInput
                              data-test-id="client-full-name"
                              disabled
                              variant="outlined"
                              value={this.state.claimData.attributes?.full_name || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_full_name"
                              checked={props.values.request_full_name}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Name of Airline</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              value={this.state.claimData.attributes?.name_of_airline || ""}
                              disabled
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_name_of_airline"
                              checked={props.values.request_name_of_airline}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Number of Bags Checked</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.number_of_bags_checked || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_number_of_bags"
                              checked={props.values.request_number_of_bags}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Credit Card</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.credit_card || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_credit_card"
                              checked={props.values.request_credit_card}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Were Points Used</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.points_used}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_points_used"
                              checked={props.values.request_points_used}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>
                      </Grid>


                      <Grid item xs={12} className={classes.contentLabel}>
                        <Typography className={classes.informationLabel}>FLIGHT INFORMATION</Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Departure City</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.departure_city || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_departure_city"
                              checked={props.values.request_departure_city}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Connection(s)</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.connections || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_connection"
                              checked={props.values.request_connection}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Confirmation Number</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.confirmation_number || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_confirmation_number"
                              checked={props.values.request_confirmation_number}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>ID Card</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={''}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_id_card"
                              checked={props.values.request_id_card}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Destination City</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.destination_city || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_destination_city"
                              checked={props.values.request_destination_city}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Airline</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.airline || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_airline"
                              checked={props.values.request_airline}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                        <Box className={classes.formControlBox}>
                          <Typography className={classes.inputLabel}>Flight Number</Typography>
                          <Box>
                            <CustomInput
                              variant="outlined"
                              disabled
                              value={this.state.claimData.attributes?.flight_number || ""}
                            />
                            <CustomCheckbox
                              icon={<CheckBoxOutlineBlankOutlined />}
                              checkedIcon={<CheckBoxOutlined />}
                              onChange={props.handleChange}
                              name="request_flight_number"
                              checked={props.values.request_flight_number}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </Box>
                        </Box>

                      </Grid>
                    </Grid>
                  </Box>

                  <Grid className={classes.buttonGroup} container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Button
                        data-test-id="request-more-button"
                        className={classes.customButton}
                        variant="outlined"
                        type="submit"
                      >
                        Request More Information from User
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <Button
                        className={classes.customButton}
                        variant="outlined"
                        onClick={this.handleExportClaim}
                        data-test-id="button-export"
                      >
                        Export
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      {this.state.claimData.attributes?.claim_status === "claim_completed" ?
                        <Button
                          className={classes.customButton}
                          variant="contained"
                          onClick={this.handleUpdateStatus}
                          data-test-id="button-claim-reopen"
                        >
                          Reopen Claim
                        </Button> :

                        <Button
                          className={classes.updatedStatusButton}
                          variant="contained"
                          onClick={this.handleUpdateStatus}
                          data-test-id="button-claim-complete"
                        >
                          Claim Completed
                        </Button>
                      }

                      <SubmitPayAmount
                        claimId={this.claimId}
                        modalOpen={this.state.modalOpen}
                        handleCloseModal={this.handleClosePayAmountModal}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )
          }}
        </Formik>

        <Portal>
          <CustomSnackbar
            isOpen={this.state.isSnackbarOpen}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={() =>
              this.setState({ isSnackbarOpen: false })
            }
            message={this.state.snackbarMessage}
            data-test-id="snack-bar"
            type={this.state.snackBarType}
          />
        </Portal>
      </Box >
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(ClaimDetailedInformation);
// Customizable Area End