import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { FormikProps } from "formik";

export type FormikFormProps = {
  pay_amount: string;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props {
  claimId: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  payAmount: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SubmitPayAmountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  formProps?: FormikProps<FormikFormProps> = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
    ];

    this.state = {
      payAmount: ""
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  updateFormProps = (props: FormikProps<FormikFormProps>) => {
    this.formProps = props;
  };

  handleSubmit = () => {
  }

  handleChangePayAmount = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const numericValue = value.replace(/\D/g, "");

    const maxAmountRegex = /^(999999999999|\d{0,12})$/;

    if (!maxAmountRegex.test(numericValue)) {
      return;
    }

    this.formProps?.setFieldValue("pay_amount", numericValue);

    this.setState({ payAmount: this.formatCurrency(numericValue) });
  }

  formatCurrency = (value: string) => {
    const numericValue = parseInt(value, 10);

    if (isNaN(numericValue)) {
      return "";
    }

    const parts = numericValue.toString().split(".");
    const integerPart = parts[0];
    const formattedIntegerPart = integerPart.split('').reverse().join('')
      .replace(/(\d{3}(?=\d))/g, '$&,')
      .split('').reverse().join('');

    return `$${formattedIntegerPart}${parts[1] ? '.' + parts[1] : ''}`;
  };

  handleClose = () => {
    this.setState({ payAmount: "" });
    this.props.handleCloseModal();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}