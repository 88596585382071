import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Portal,
  createStyles,
  withStyles
} from "@material-ui/core";

import { styled } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Search, MoreVert, ExpandMore } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';

import ClaimsDashboardController, {
  FilterMenuProps,
  MenuType,
  Props,
  RowMenuProps,
  SortByOption,
  SortMenuProps,
  TableHeader,
  formatClaimsDate,
  renameClaimStatus
} from "./ClaimsDashboardController.web";
import Loader from "../../../components/src/Loader.web";
import { datePicker, filterIcon, sortDecrease } from "./assets";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CustomSnackbar } from "../../../blocks/forgot-password/src/ForgotPassword.web";
import { EmailAccountLogout } from "../../../blocks/email-account-login/src/EmailAccountLogout.web";
import { SubmitPayAmount } from "../../../blocks/customform/src/SubmitPayAmount.web";

const logo = require("../../../components/src/main_logo.png");

const styles = createStyles({
  root: {
    display: 'flex',
    height: '100vh'
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
    borderCollapse: 'separate',
    borderSpacing: '0px 20px'
  },
  customeLeftbar: {
    width: 120,
    background: '#0E0F2B'
  },
  leftbarContainer: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 10,
    justifyContent: 'space-between',
    margin: '15px 0',
    alignItems: 'center'
  },
  logoImg: {
    width: '100px',
    height: '100px',
    margin: 0,
    justifyContent: 'center'
  },
  mainArea: {
    flexGrow: 1,
    padding: '50px',
    height: '100%',
    background: "#F1F5F9",
    overflowY: 'scroll',
    minWidth: 500
  },
  headerArea: {
    padding: '0 20px'
  },
  formArea: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '50px'
  },
  headerButtonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '30px'
  },
  clientName: {
    fontSize: '14px',
    fontWeight: 700,
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  tableCellText: {
    fontWeight: 400,
    fontSize: '14px'
  },
  paginateArea: {
    '& .MuiPagination-root': {
      display: 'flex',
      justifyContent: 'end'
    }
  },
  statusCell: {
    fontWeight: 700,
    fontSize: '14px',
    color: '#44B400'
  },
  actionButton: {
    color: '#FFFFFF'
  },
  buttonImage: {
    marginRight: 10
  },
  filterActionLables: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  filterButton: {
    height: 32,
    borderRadius: 8,
    fontSize: 12,
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none'
  },
  selectedButton: {
    background: '#FAA52E'
  },
  normalButton: {
    background: '#E2E8F0'
  },
  filterMenuPadding: {
    padding: '0 10px'
  },
  sortByActionLables: {
    display: 'flex',
    justifyContent: 'end'
  },
  selectOption: {
    fontSize: 14
  },
  customLink: {
    color: '#000',
    textDecoration: 'none'
  }
});

const CustomLeftbar = styled(Drawer)(() => ({
  width: 120,
  flexShrink: 0
}));

const CustomScreenLable = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 24,
  lineHeight: '32px',
  color: '#0F172A'
}));

const CustomClaimsLable = styled(Typography)(() => ({
  fontFamily: '"Inter", sans-serif',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
  color: '#64748B'
}));

const CustomHeaderButton = styled(Button)(() => ({
  width: 'auto',
  height: 50,
  borderRadius: '8px',
  border: '1px solid #E2E8F0',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  textTransform: 'none',
  color: '#0F172A'
}));

const CustomTableHeader = styled(TableHead)(() => ({
  '& th:first-child': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '& th:last-child': {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}));

const CustomHeaderTableCell = styled(TableCell)(() => ({
  color: '#FFFFFF',
  fontWeight: 700,
  fontSize: '14px',
  background: '#0E0F2B'
}));

const CustomTableRow = styled(TableRow)(() => ({
  background: "#FFFFFF",
  height: 72,
  '& td:first-child': {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  },
  '& td:last-child': {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  borderRadius: '8px',
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  cursor: 'pointer',
}));

const CustomEmptyRow = styled(TableRow)(() => ({
  height: 30,
  '& td': {
    textAlign: 'center',
  },
}));

const CustomPagination = styled(Pagination)(() => ({
  '& .Mui-selected': {
    background: "none",
    color: "#6200EA",
    fontWeight: 700
  },
  '& .MuiPaginationItem-page': {
    fontSize: 12
  }
}));

const CustomRowMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    boxShadow: 'rgba(0, 0, 0, 0.07) 0px 2px 5px',
    border: '1px solid #ddd',
    width: 192
  },
  '& .MuiListItem-button:hover': {
    backgroundColor: '#E0E0E0'
  }
}));

const CustomFilterMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    boxShadow: '0px 4px 4px 0px #00000040;',
    border: '1px solid #ddd',
    marginTop: 20,
    padding: 15
  },
}));

const CustomFilterBox = styled(Box)(() => ({
  width: 319,
  height: 'auto',
  maxHeight: 480
}));

const CustomDivider = styled(Divider)(() => ({
  color: '#64748B',
  margin: '10px -15px'
}));

const CustomDatePicker = styled(DatePicker)(() => ({
  '& .MuiInputBase-root': {
    height: 32
  },
  '& .MuiInputBase-input': {
    fontSize: 14
  }
}));

const CustomSelectFormControl = styled(FormControl)(() => ({
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'white',
    padding: '0px 4px',
  },
  display: 'flex'
}));

const CustomSelect = styled(Select)({
  height: 32,
  display: 'flex',
  alignItems: 'center',
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px',
  },
  fontSize: 14
});

const CustomSearchInput = styled(TextField)(() => ({
  height: 32,
  '& .MuiOutlinedInput-root': {
    height: 32,
    padding: 0,
    '& .MuiOutlinedInput-input': {
      height: '100%',
      boxSizing: 'border-box',
      fontSize: 14
    },
    '& .MuiInputAdornment-root': {
      height: '100%',
      paddingLeft: 5
    },
  },
}));

const CustomResetLabel = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 700,
  color: '#FAA52E',
  cursor: 'pointer'
}));

const CustomFilterLabel = styled(Typography)(() => ({
  fontSize: '14px'
}));

const CustomSortByMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    boxShadow: '0px 4px 4px 0px #00000040;',
    border: '1px solid #ddd',
    marginTop: 20,
    padding: 15
  },
}));

const CustomSortBox = styled(Box)(() => ({
  width: 319,
  height: 'auto'
}));

const CustomErrorLabel = styled(Typography)(() => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  color: "red"
}));

const CustomDatePickerIcon = () => (
  <img
    src={datePicker.default}
    style={{ width: 15, height: 15 }}
  />
);

const validationSchema = Yup.object({
  date_to: Yup.date()
    .test('dateOrder', 'To date must be after From date', function (value) {
      const { date_from } = this.parent;
      return !date_from || !value || new Date(date_from) <= new Date(value);
    })
});

const FilterMenu = ({
  anchorEl,
  open,
  onClose,
  classes,
  handleClickApply,
  handleClickReset,
  updateFilterFormProps,
  handleOnChangeDatePicker,
  handleClickResetField,
  claimCategories,
  isDatePickerFromOpen,
  isDatePickerToOpen,
  handleDatePickerOpen,
  handleDatePickerClose
}: FilterMenuProps) => (
  <CustomFilterMenu
    anchorEl={anchorEl}
    keepMounted
    open={open}
    onClose={onClose}
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    getContentAnchorEl={null}
    className={classes.filterMenuPadding}
    data-test-id="custom-filter-menu"
  >
    <Formik
      initialValues={{
        date_from: "",
        date_to: "",
        claim_type: "",
        claims_status: "",
        keyword_search: ""
      }}
      validationSchema={validationSchema}
      onSubmit={handleClickApply}
      data-test-id="filter-formik"
    >
      {(props) => {
        updateFilterFormProps(props);

        return (
          <Form
            data-test-id="filter-form"
            onSubmit={props.handleSubmit}
          >
            <CustomFilterBox>
              <CustomFilterLabel>Filter</CustomFilterLabel>

              <CustomDivider />

              <Box className={classes.filterActionLables}>
                <CustomFilterLabel>Date range</CustomFilterLabel>

                <CustomResetLabel
                  data-test-id="reset-claim-date"
                  onClick={() => handleClickResetField("date")}
                >
                  Reset
                </CustomResetLabel>
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <CustomFilterLabel style={{ marginBottom: 10 }}>From</CustomFilterLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      format="MM/DD/YYYY"
                      slots={{ openPickerIcon: CustomDatePickerIcon }}
                      data-test-id="date-picker-from"
                      onChange={(value) => handleOnChangeDatePicker(value, 'date_from')}
                      value={props.values.date_from ? props.values.date_from : null}
                      name="date_from"
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          error: props.touched.date_from && !!props.errors.date_from,
                        },
                      }}
                      disableFuture
                      open={isDatePickerFromOpen}
                      onOpen={() => handleDatePickerOpen("from")}
                      onClose={() => handleDatePickerClose("from")}
                    />
                  </LocalizationProvider>

                </Grid>

                <Grid item xs={6}>
                  <CustomFilterLabel style={{ marginBottom: 10 }}>To</CustomFilterLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CustomDatePicker
                      format="MM/DD/YYYY"
                      data-test-id="date-picker-to"
                      slots={{ openPickerIcon: CustomDatePickerIcon }}
                      onChange={(value) => handleOnChangeDatePicker(value, 'date_to')}
                      value={props.values.date_to ? props.values.date_to : null}
                      name="date_to"
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          error: props.touched.date_to && !!props.errors.date_to,
                          helperText:
                            <CustomErrorLabel
                              data-test-id="date-to-error-text"
                            >
                              {props.errors.date_to}
                            </CustomErrorLabel>
                        },
                      }}
                      disableFuture
                      open={isDatePickerToOpen}
                      onOpen={() => handleDatePickerOpen("to")}
                      onClose={() => handleDatePickerClose("to")}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <CustomDivider />

              <Box className={classes.filterActionLables}>
                <CustomFilterLabel>Claim type</CustomFilterLabel>
                <CustomResetLabel
                  onClick={() => handleClickResetField("type")}
                  data-test-id="reset-claim-category"
                >
                  Reset
                </CustomResetLabel>
              </Box>

              <CustomSelectFormControl variant="outlined">
                <CustomSelect
                  native
                  onChange={props.handleChange}
                  name="claim_type"
                  value={props.values.claim_type}
                  IconComponent={ExpandMore}
                  data-test-id="claim-category"
                >
                  <option aria-label="None" value=""></option>
                  {claimCategories.map((category) =>
                    <option key={category.name} value={category.name}>{category.name}</option>
                  )}
                </CustomSelect>
              </CustomSelectFormControl>

              <CustomDivider />

              <Box className={classes.filterActionLables}>
                <CustomFilterLabel>Claim status</CustomFilterLabel>
                <CustomResetLabel
                  data-test-id="reset-claim-status"
                  onClick={() => handleClickResetField("status")}
                >
                  Reset
                </CustomResetLabel>
              </Box>

              <CustomSelectFormControl variant="outlined">
                <CustomSelect
                  native
                  onChange={props.handleChange}
                  name="claims_status"
                  value={props.values.claims_status}
                  IconComponent={ExpandMore}
                >
                  <option aria-label="None" value="" />
                  <option value="claim_completed">Completed</option>
                  <option value="requested_more_info">Requested more info</option>
                  <option value="exported_info">Exported Info</option>
                  <option value="open">Open</option>
                  <option value="reopen">Reopen</option>
                </CustomSelect>
              </CustomSelectFormControl>

              <CustomDivider />

              <Box className={classes.filterActionLables}>
                <CustomFilterLabel>Keyword Search</CustomFilterLabel>
                <CustomResetLabel
                  data-test-id="reset-claim-keyword"
                  onClick={() => handleClickResetField("keyword")}
                >
                  Reset
                </CustomResetLabel>
              </Box>

              <FormControl variant="outlined" style={{ display: 'flex' }}>
                <CustomSearchInput
                  variant="outlined"
                  className={classes.margin}
                  value={props.values.keyword_search}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  onChange={props.handleChange}
                  name="keyword_search"
                  helperText={
                    <CustomErrorLabel
                      data-test-id="keyword-search-error-text"
                    >
                      {props.errors.keyword_search}
                    </CustomErrorLabel>
                  }
                  error={props.touched.keyword_search && !!props.errors.keyword_search}
                />
              </FormControl>

              <CustomDivider />

              <Box className={classes.filterActionLables}>
                <Button
                  className={`${classes.filterButton} ${classes.normalButton}`}
                  onClick={handleClickReset}
                  data-test-id="reset-all-button"
                >
                  Reset all
                </Button>
                <Button
                  className={`${classes.filterButton} ${classes.selectedButton}`}
                  type="submit"
                >
                  Apply now
                </Button>
              </Box>

            </CustomFilterBox>
          </Form>
        );
      }}
    </Formik>
  </CustomFilterMenu>
);

const SortByMenu = ({
  anchorEl,
  open,
  onClose,
  classes,
  handleClickApply,
  handleChangeDateSortType,
  dateSortType
}: SortMenuProps) => (
  <CustomSortByMenu
    anchorEl={anchorEl}
    keepMounted
    open={open}
    onClose={onClose}
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    getContentAnchorEl={null}
    className={classes.filterMenuPadding}
  >
    <CustomSortBox>
      <CustomFilterLabel>Sort By</CustomFilterLabel>
      <CustomDivider />

      <Box className={classes.filterActionLables}>
        <CustomFilterLabel>Claim Date</CustomFilterLabel>
      </Box>

      <CustomSelectFormControl variant="outlined">
        <CustomSelect
          native
          onChange={handleChangeDateSortType}
          value={dateSortType}
          IconComponent={ExpandMore}
          data-test-id="sort-date-type"
        >
          <option
            value={SortByOption.Descending}
            className={classes.selectOption}
          >
            Newest to Oldest
          </option>
          <option
            value={SortByOption.Ascending}
            className={classes.selectOption}
          >
            Oldest to Newest
          </option>
        </CustomSelect>
      </CustomSelectFormControl>

      <CustomDivider />

      <Box className={classes.sortByActionLables}>
        <Button
          className={`${classes.filterButton} ${classes.selectedButton}`}
          onClick={handleClickApply}
          data-test-id="apply-sort-button"
        >
          Apply now
        </Button>
      </Box>

    </CustomSortBox>
  </CustomSortByMenu>
);

const RowMenu = ({
  anchorEl,
  open,
  onClose,
  isDisableClaimComplete,
  isDisableClaimReopen,
  handleUpdateStatus,
  handleExportData
}: RowMenuProps) => (
  <CustomRowMenu
    anchorEl={anchorEl}
    keepMounted
    open={open}
    onClose={onClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{ vertical: "top", horizontal: "right" }}
    getContentAnchorEl={null}
    onClick={(e) => e.stopPropagation()}
  >
    <MenuItem
      style={{ fontSize: 14 }}
      onClick={handleExportData}
      data-test-id="button-export"
    >
      Export
    </MenuItem>

    <MenuItem
      style={{ fontSize: 14 }}
      disabled={isDisableClaimComplete}
      onClick={(e) => handleUpdateStatus(e, "claim_completed")}
      data-test-id="button-claim-completed"
    >
      Claim Complete
    </MenuItem>

    <MenuItem
      style={{ fontSize: 14 }}
      onClick={(e) => handleUpdateStatus(e, "reopen")}
      disabled={isDisableClaimReopen}
      data-test-id="button-claim-reopened"
    >
      Reopen
    </MenuItem>
  </CustomRowMenu>
);
// Customizable Area End

export class ClaimsDashboard extends ClaimsDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      <Box className={classes.root}>
        <Loader loading={this.state.loading} />

        <CssBaseline />

        <CustomLeftbar
          variant="permanent"
          PaperProps={{
            style: {
              width: 120,
              background: '#0E0F2B'
            }
          }}
          data-test-id="left-bar"
        >
          <div className={classes.leftbarContainer}>
            <img src={logo?.default || logo || ""} className={classes.logoImg} />
            <EmailAccountLogout
              token={this.state.token}
            />
          </div>
        </CustomLeftbar>

        <Box className={classes.mainArea}>
          <Box className={classes.headerArea}>
            <CustomScreenLable data-test-id="screen-label">Claim management</CustomScreenLable>
          </Box>

          <Box className={classes.formArea}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <CustomClaimsLable data-test-id="claims-label">Claims</CustomClaimsLable>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Box className={classes.headerButtonGroup}>
                  <Box>
                    <CustomHeaderButton
                      data-test-id="sort-button"
                      onClick={(e) => this.handleClickShowMenu(e, MenuType.SortByMenu)}
                      className={this.state.anchorSortEl ? classes.selectedButton : classes.normalButton}

                    >
                      <img src={sortDecrease?.default || sortDecrease || ""} className={classes.buttonImage} />
                      Sort by
                    </CustomHeaderButton>

                    <SortByMenu
                      data-test-id="sort-menu"
                      anchorEl={this.state.anchorSortEl}
                      open={Boolean(this.state.anchorSortEl)}
                      onClose={(e: React.MouseEvent<HTMLElement>) => this.handleClickCloseMenu(e, MenuType.SortByMenu)}
                      classes={classes}
                      handleClickApply={this.handleApplySort}
                      handleChangeDateSortType={this.handleChangeDateSortType}
                      dateSortType={this.state.dateSort}
                    />
                  </Box>

                  <Box>
                    <CustomHeaderButton
                      onClick={(e) => this.handleClickShowMenu(e, MenuType.FilterMenu)}
                      className={this.state.anchorFilterEl ? classes.selectedButton : classes.normalButton}
                      data-test-id="filter-button"
                    >
                      <img src={filterIcon?.default || filterIcon || ""} className={classes.buttonImage} />
                      Filter
                    </CustomHeaderButton>

                    <FilterMenu
                      anchorEl={this.state.anchorFilterEl}
                      open={Boolean(this.state.anchorFilterEl)}
                      onClose={(e: React.MouseEvent<HTMLElement>) => this.handleClickCloseMenu(e, MenuType.FilterMenu)}
                      classes={classes}
                      data-test-id="filter-menu"
                      handleClickApply={() => this.handleApplyFilter(1)}
                      handleClickReset={this.handleResetAllFilter}
                      updateFilterFormProps={this.updateFilterFormProps}
                      handleOnChangeDatePicker={this.handleOnChangeDatePicker}
                      handleClickResetField={this.handleResetFieldFilter}
                      claimCategories={this.state.claimCategories}
                      isDatePickerFromOpen={this.state.isDatePickerFromOpen}
                      isDatePickerToOpen={this.state.isDatePickerToOpen}
                      handleDatePickerOpen={this.handleDatePickerOpen}
                      handleDatePickerClose={this.handleDatePickerClose}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <TableContainer>
            <Table className={classes.table}>
              <CustomTableHeader data-test-id="table-header">
                <TableRow>
                  <CustomHeaderTableCell><Avatar /></CustomHeaderTableCell>
                  <CustomHeaderTableCell>{TableHeader.ClientName}</CustomHeaderTableCell>
                  <CustomHeaderTableCell>{TableHeader.ClaimType}</CustomHeaderTableCell>
                  <CustomHeaderTableCell>{TableHeader.ClaimNumber}</CustomHeaderTableCell>
                  <CustomHeaderTableCell>{TableHeader.ClaimStatus}</CustomHeaderTableCell>
                  <CustomHeaderTableCell>{TableHeader.TravelAgent}</CustomHeaderTableCell>
                  <CustomHeaderTableCell>{TableHeader.ClaimDate}</CustomHeaderTableCell>

                  <CustomHeaderTableCell
                    align="right"
                    data-test-id="header-cell"
                  >
                    <IconButton className={classes.actionButton}>
                      <MoreVert />
                    </IconButton>
                  </CustomHeaderTableCell>
                </TableRow>
              </CustomTableHeader>

              <TableBody>
                {this.state.listClaims && this.state.listClaims.length > 0 ?
                  this.state.listClaims.map((data, index) =>
                    <CustomTableRow
                      key={index}
                      hover
                      data-test-id={"table-row-" + index}
                      onClick={() => this.handleClickRow(data.id)}
                    >
                      <TableCell><Avatar /></TableCell>
                      <TableCell className={classes.clientName}>
                        <Tooltip arrow className={classes.customTooltip} title={data.attributes.full_name ?? ""} placement="top-start">
                          <p className={classes.clientName}>{data.attributes.full_name}</p>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.tableCellText}>{data.attributes.claim_category_name}</TableCell>
                      <TableCell className={classes.tableCellText}>{data.id}</TableCell>
                      <TableCell className={classes.statusCell}>{renameClaimStatus(data.attributes.claim_status)}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>{formatClaimsDate(data.attributes.claim_date)}</TableCell>

                      <TableCell align="right">

                        <IconButton
                          onClick={(e) => this.handleClickShowMenu(e, MenuType.RowMenu, data)}
                          data-test-id={"row-button-" + index}
                        >
                          <MoreVert />
                        </IconButton>

                        <RowMenu
                          anchorEl={this.state.anchorRowEl}
                          open={Boolean(this.state.anchorRowEl)}
                          onClose={(e) => this.handleClickCloseMenu(e, MenuType.RowMenu)}
                          data-test-id={"row-menu-" + index}
                          classes={classes}
                          claimId={this.state.selectedClaim.id}
                          isDisableClaimComplete={this.state.selectedClaim.attributes.claim_status === "claim_completed"}
                          isDisableClaimReopen={
                            this.state.selectedClaim.attributes.claim_status === "reopen" ||
                            this.state.selectedClaim.attributes.claim_status !== "claim_completed"
                          }
                          handleUpdateStatus={this.handleUpdateStatus}
                          handleExportData={this.handleExportData}
                        />

                      </TableCell>
                    </CustomTableRow>
                  ) :
                  <CustomEmptyRow>
                    <TableCell colSpan={8} >No Record Found</TableCell>
                  </CustomEmptyRow>
                }
              </TableBody>
            </Table>
          </TableContainer>

          <Box className={classes.paginateArea}>
            <CustomPagination
              count={this.state.pagesNumber}
              page={this.state.page}
              onChange={this.handleChangePage}
              data-test-id="pagination"
            />
          </Box>
        </Box>

        <Portal>
          <CustomSnackbar
            isOpen={this.state.isSnackbarOpen}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            onClose={() =>
              this.setState({ isSnackbarOpen: false })
            }
            message={this.state.snackbarMessage}
            data-test-id="snack-bar"
            type={this.state.snackBarType}
          />
        </Portal>

        <SubmitPayAmount
          data-test-id="pay-amount-modal"
          claimId={this.state.selectedClaim.id}
          modalOpen={this.state.modalOpen}
          handleCloseModal={this.handleClosePayAmountModal}
        />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(ClaimsDashboard);
// Customizable Area End