import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { sessionProvider } from "../../../blocks/forgot-password/src/ForgotPasswordController.web";
import { defaultClaimValue } from "../../../blocks/multipageforms/src/ClaimDetailedInformationController.web";
import { baseURL } from "../../../blocks/bulkuploading/src/BulkUploadingController";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export enum TableHeader {
  ClientName = "Client Name",
  ClaimType = "Claim Type",
  ClaimNumber = "Claim Number",
  ClaimStatus = "Claim Status",
  TravelAgent = "Travel Agent",
  ClaimDate = "Claim Date",
};

export enum SortByOption {
  Descending = "desc",
  Ascending = "asc",
};

export enum MenuType {
  RowMenu,
  FilterMenu,
  SortByMenu
};

export type DamagedLuggagePhoto = {
  id: number;
  content_type: string;
  file_name: string;
  url: string;
}

export type Claims = {
  id: string;
  type: string;
  attributes: {
    account_id: string | null;
    pnr: string | null;
    claim_category_id: string | null;
    name_of_airline: string | null;
    number_of_bags_checked: number;
    full_name: string | null;
    flight_number: string | null;
    credit_card: string | null;
    points_used: string | null;
    departure_city: string | null;
    destination_city: string | null;
    connections: string | null;
    airline: string | null;
    claim_status: string | null;
    confirmation_number: string | null;
    description_of_luggage_contents: string | null;
    updated_at: string | null;
    status: string | null;
    agreed: string | null;
    claim_category_name: string | null;
    claim_date: string;
    damaged_luggage_photos: Array<DamagedLuggagePhoto> | null;
  }
}

export type ClaimCategory = {
  id: number,
  name: string,
  created_at: string,
  updated_at: string | null;
}

export type FormikFilterProps = {
  date_from: string;
  date_to: string;
  claim_type: string;
  claims_status: string;
  keyword_search: string;
}

export const requestApi = (header: object, method: string, endPoint: string, data?: object) => {

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  if (data) {
    const httpBody = data;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  }

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return requestMessage.messageId;
}

export const renameClaimStatus = (status: string | null) => {
  switch (status) {
    case "exported_info":
      return "Exported Info";
    case "requested_more_info":
      return "Requested More Info";
    case "claim_completed":
      return "Completed";
    case "reopen":
      return "Reopen";
    case "open":
      return "Open";
    default:
      return "";
  }
}

export const formatClaimsDate = (dateStr: string | null) => {
  if (dateStr) {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = date.getFullYear();

    const formattedDate = `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
    return formattedDate;
  }
  return null;
}

export const getOrdinalSuffix = (day: number) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const relevantDigits = day % 100;

  return suffixes[(relevantDigits - 20) % 10] || suffixes[relevantDigits] || suffixes[0];
};

export interface FilterMenuProps {
  anchorEl: any;
  open: any;
  onClose: any;
  classes: any;
  claimCategories: Array<ClaimCategory>;
  handleClickApply: () => void;
  handleClickReset: () => void;
  handleOnChangeDatePicker: (value: any | null, date_type: string) => void;
  updateFilterFormProps: (props: FormikProps<FormikFilterProps>) => void;
  handleClickResetField: (field: string) => void;
  isDatePickerFromOpen: boolean;
  isDatePickerToOpen: boolean;
  handleDatePickerOpen: (type: string) => void;
  handleDatePickerClose: (type: string) => void;
}

export interface SortMenuProps {
  anchorEl: any;
  open: any;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  classes: any;
  handleClickApply: () => void;
  handleChangeDateSortType: (event: React.ChangeEvent<{ value: unknown }>) => void;
  dateSortType: string;
}

export interface RowMenuProps {
  anchorEl: any;
  open: any;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  classes: any;
  claimId: string;
  isDisableClaimComplete: boolean;
  isDisableClaimReopen: boolean;
  handleUpdateStatus: (event: React.MouseEvent<HTMLElement>, status: string) => void;
  handleExportData: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  listClaims: Array<Claims>;
  token: string;
  errorMessage: string;
  rowsPerPage: number;
  page: number;
  pagesNumber: number;
  anchorRowEl: HTMLElement | null;
  anchorFilterEl: HTMLElement | null;
  anchorSortEl: HTMLElement | null;
  isSnackbarOpen: boolean;
  snackbarMessage: string;
  dateSort: string;
  claimCategories: Array<ClaimCategory>;
  selectedClaim: Claims;
  isClickedFilter: boolean;
  isDatePickerFromOpen: boolean;
  isDatePickerToOpen: boolean;
  snackBarType: string;
  selectedStatus: string;
  modalOpen: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ClaimsDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllClaimsApiId: string = "";
  getAllClaimCategoriesApiId: string = "";
  filterApiId: string = "";
  updateStatusApiId: string = "";

  filterFormProps?: FormikProps<FormikFilterProps> = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.LoginSuccessMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      listClaims: [],
      loading: false,
      token: "",
      errorMessage: "",
      rowsPerPage: 6,
      page: 1,
      pagesNumber: 0,
      anchorRowEl: null,
      anchorFilterEl: null,
      anchorSortEl: null,
      isSnackbarOpen: false,
      snackbarMessage: "",
      dateSort: "desc",
      claimCategories: [],
      selectedClaim: defaultClaimValue,
      isClickedFilter: false,
      isDatePickerFromOpen: false,
      isDatePickerToOpen: false,
      snackBarType: "error",
      selectedStatus: "",
      modalOpen: false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.redirection();
    // Customizable Area End
  }

  // Customizable Area Start
  async redirection() {
    const token = await StorageProvider.get("token");
    const localSTokenType = await StorageProvider.get("token_type");

    const sessionToken = sessionProvider("get", "token");
    const sessionTokenType = sessionProvider("get", "token_type");

    if ((token && localSTokenType == "login")) {
      this.getAllClaims(token, this.state.page);
      this.getAllClaimCategories(token);

      this.setState({ token: token });
      return;
    }

    if ((sessionToken && sessionTokenType == "login")) {
      this.getAllClaims(sessionToken, this.state.page);
      this.getAllClaimCategories(sessionToken);

      this.setState({ token: sessionToken });
      return;
    }

    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  updateFilterFormProps = (props: FormikProps<FormikFilterProps>) => {
    this.filterFormProps = props;
  };

  convertDateFormat = (dateStr: any) => {
    if (!dateStr) {
      return dateStr;
    }

    const [month, day, year] = dateStr.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  handleApplyFilter = (page: number) => {
    this.setState({
      loading: true,
      isClickedFilter: true,
      page: page,
      anchorFilterEl: null
    });

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const endPoint = "bx_block_custom_forms/claims?"
      + "from=" + this.convertDateFormat(this.filterFormProps?.values.date_from)
      + "&to=" + this.convertDateFormat(this.filterFormProps?.values.date_to)
      + "&claim_category=" + this.filterFormProps?.values.claim_type
      + "&claim_status=" + this.filterFormProps?.values.claims_status
      + "&search=" + this.filterFormProps?.values.keyword_search
      + "&sort_by=created_at"
      + "&sort_order=" + this.state.dateSort
      + "&page=" + page
      + "&per_page=" + this.state.rowsPerPage

    this.filterApiId = requestApi(header, configJSON.getApiMethod, endPoint);
  };

  handleApplySort = () => {
    this.setState({
      anchorSortEl: null
    });

    this.handleApplyFilter(1);
  }

  handleShowApiMessage = (responseJson: any, showSuccessMessage: boolean = false) => {
    this.setState({ loading: false });

    if (responseJson.error) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: responseJson.error,
        snackBarType: "error"
      });

      return false;
    }

    if (responseJson.errors && responseJson.errors[0]) {
      this.setState({
        isSnackbarOpen: true,
        snackbarMessage: responseJson.errors[0],
        snackBarType: "error"
      })

      return false;
    }

    if (showSuccessMessage) {
      this.setState({
        isSnackbarOpen: true,
        snackBarType: "success",
        snackbarMessage: responseJson.message,
      });
    }

    return true;
  }

  handleApplyFilterResponse = async (responseJson: any) => {
    if (!this.handleShowApiMessage(responseJson)) {
      return;
    }

    this.setState({
      listClaims: responseJson.data,
      pagesNumber: responseJson.meta?.totalPages
    });
  }

  handleResetAllFilter = () => {
    if (this.filterFormProps) {
      this.filterFormProps.resetForm();

      this.filterFormProps.setValues({
        date_from: "",
        date_to: "",
        claim_type: "",
        claims_status: "",
        keyword_search: "",
      });
    }
  };

  handleResetFieldFilter = (field: string) => {
    if (this.filterFormProps) {
      switch (field) {
        case "date":
          this.filterFormProps?.setFieldValue("date_from", "");
          this.filterFormProps.setFieldValue("date_to", "");
          break;
        case "type":
          this.filterFormProps.setFieldValue("claim_type", "");
          break;
        case "status":
          this.filterFormProps.setFieldValue("claims_status", "");
          break;
        default:
          this.filterFormProps.setFieldValue("keyword_search", "");
          break;
      }
    }
  }

  handleOnChangeDatePicker = (value: any, date_type: string) => {
    let newDate: string | null = null;

    if (value) {
      newDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    }

    this.filterFormProps?.setFieldValue(date_type, newDate);
  };

  getAllClaims = (token: string, page: number) => {
    this.setState({
      loading: true,
      page: page
    });

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    this.getAllClaimsApiId = requestApi(header, configJSON.getApiMethod, "bx_block_custom_forms/claims?page=" + page + "&per_page=" + this.state.rowsPerPage);
  };

  getAllClaimCategories = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    this.getAllClaimCategoriesApiId = requestApi(header, configJSON.getApiMethod, "bx_block_custom_forms/claims/fetch_claim_category");
  }

  handleGetAllClaimTypeResponse = async (responseJson: any) => {
    if (!this.handleShowApiMessage(responseJson)) {
      return;
    }

    this.setState({ claimCategories: responseJson.claim_category });
  }

  handleGetAllClaimApiResponse = async (responseJson: any) => {
    if (!this.handleShowApiMessage(responseJson)) {
      return;
    }

    this.setState({
      listClaims: responseJson.data,
      pagesNumber: responseJson.meta?.totalPages
    });
  }

  handleChangePage = (event: any, newPage: number) => {
    if (this.state.isClickedFilter) {
      this.handleApplyFilter(newPage)
    } else {
      this.getAllClaims(this.state.token, newPage);
    }
  };

  handleClickShowMenu = (event: React.MouseEvent<HTMLButtonElement>, menuType: any, claim: Claims = defaultClaimValue) => {
    event.stopPropagation();

    const anchor = event.currentTarget;

    switch (menuType) {
      case MenuType.FilterMenu:
        this.setState({ anchorFilterEl: anchor });
        break;
      case MenuType.SortByMenu:
        this.setState({ anchorSortEl: anchor });
        break;
      default:
        this.setState({
          anchorRowEl: anchor,
          selectedClaim: claim
        });
        break;
    }
  }

  handleClickCloseMenu = (event: React.MouseEvent<HTMLElement>, menuType: any) => {
    event.stopPropagation();

    switch (menuType) {
      case MenuType.FilterMenu:
        this.setState({ anchorFilterEl: null });
        break;
      case MenuType.SortByMenu:
        this.setState({ anchorSortEl: null });
        break;
      default:
        this.setState({ anchorRowEl: null });
        break;
    }

    this.setState({
      isDatePickerFromOpen: false,
      isDatePickerToOpen: false
    });
  }

  handleDatePickerOpen = (type: string) => {
    if (type === "from") {
      this.setState({ isDatePickerFromOpen: true });
    } else {
      this.setState({ isDatePickerToOpen: true });
    }
  };

  handleDatePickerClose = (type: string) => {
    if (type === "from") {
      this.setState({ isDatePickerFromOpen: false });
    } else {
      this.setState({ isDatePickerToOpen: false });
    }
  };

  handleChangeDateSortType = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ dateSort: (event.target.value as string) });
  };

  handleUpdateStatus = (event: React.MouseEvent<HTMLElement>, status: string) => {
    event.stopPropagation();

    this.setState({
      loading: true,
      anchorRowEl: null,
      selectedStatus: status
    });

    const claimStatus = this.state.selectedClaim.attributes.claim_status;

    if (claimStatus !== "claim_completed") {
      this.handleOpenPayAmountModal();
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const data = {
      claim_id: this.state.selectedClaim.id,
      claim: {
        claim_status: status
      }
    }

    this.updateStatusApiId = requestApi(
      header,
      configJSON.putApiMethod,
      "bx_block_custom_forms/claims/update_claim_status",
      data
    );
  }

  handleUpdateStatusResponse = async (responseJson: any) => {
    if (!this.handleShowApiMessage(responseJson, true)) {
      return;
    }

    this.setState(prevState => ({
      selectedClaim: {
        ...prevState.selectedClaim,
        attributes: {
          ...prevState.selectedClaim.attributes,
          claim_status: this.state.selectedStatus
        }
      }
    }));

    this.updateRowStatus(this.state.selectedClaim.id, this.state.selectedStatus);
  }

  updateRowStatus = (claimId: string, newStatus: string) => {
    const updatedData = this.state.listClaims.map((item) => {
      if (item.id === claimId) {
        return {
          ...item,
          attributes: {
            ...item.attributes,
            claim_status: newStatus,
          },
        };
      }
      return item;
    });

    this.setState({
      listClaims: updatedData
    });
  };

  handleClickRow = (claimId: string) => {
    this.props.navigation.navigate("ClaimDetailedInformation", { path: { claimId: claimId } });
  }

  handleExportData = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    this.setState({ loading: true });

    const endPoint = baseURL
      + "/bx_block_custom_forms/claims/export_claim_data?claim_id="
      + this.state.selectedClaim.id + "&token="
      + this.state.token;

    window.open(endPoint, "_blank");

    this.updateRowStatus(this.state.selectedClaim.id, "exported_info");

    this.setState({
      loading: false,
      isSnackbarOpen: true,
      snackBarType: "success",
      snackbarMessage: "Export file successfully!",
      anchorRowEl: null
    });
  }

  handleClosePayAmountModal = () => {
    this.setState({ modalOpen: false });
  };

  handleOpenPayAmountModal = () => {
    this.setState({ modalOpen: true });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseData = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestErrorMessage = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestErrorMessage !== undefined) {
      this.setState({
        loading: false,
        snackBarType: "error",
        isSnackbarOpen: true,
        snackbarMessage: apiRequestErrorMessage,
      })
      return;
    }

    if (apiRequestId === this.getAllClaimsApiId) {
      this.handleGetAllClaimApiResponse(responseData);
    }

    if (apiRequestId === this.getAllClaimCategoriesApiId) {
      this.handleGetAllClaimTypeResponse(responseData);
    }

    if (apiRequestId === this.filterApiId) {
      this.handleApplyFilterResponse(responseData);
    }

    if (apiRequestId === this.updateStatusApiId) {
      this.handleUpdateStatusResponse(responseData);
    }
    // Customizable Area End
  }
}